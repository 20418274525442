const navLinks = [
	{
		title: "About",
		linkTo: "#about",
		section: "about",
	},
	{
		title: "Projects",
		linkTo: "#projects",
		section: "projects",
	},
	{
		title: "Contact",
		linkTo: "#contact",
		section: "contact",
	},
];

export default navLinks;
